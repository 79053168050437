import debounce from '../../../shared/utils/debounce';
import { Vuid } from '../../../shared/types/utils';
import { registerSetter } from '../../../entry/publicMethods/set/set';
import { getExpressedConsentFromStringOrBoolean } from '../../../shared/consentHelpers';
import {
  ExpressedConsent,
  StringOrBoolConsent,
} from '../../../shared/types/consent';
import { DispatcherWithRegistry } from '../../../live/src/event-manager/dispatcherWithRegistry';

type GdprService = {
  getConsentForVuid: (vuid: Vuid, actionId: string) => void;
  setConsentForVuid: (vuid: Vuid, consent: ExpressedConsent) => void;
};

export default (
  gdprService: GdprService,
  dispatcher: DispatcherWithRegistry,
) => {
  const setGdprConsent = (consent: StringOrBoolConsent) => {
    const expressedConsent = getExpressedConsentFromStringOrBoolean(consent);
    if (expressedConsent === null) {
      return;
    }
    if (expressedConsent === false) {
      dispatcher.emit(
        'EngagementVisitorGDPRConsentRefused',
        'WebSDK',
        'ORIGINAL',
      );
    } else {
      dispatcher.emit(
        'EngagementVisitorGDPRConsentAccepted',
        'WebSDK',
        'ORIGINAL',
      );
    }

    gdprService.setConsentForVuid(window.iAdvize.vuid, expressedConsent);
  };

  registerSetter('visitor:GDPRConsent', debounce(setGdprConsent, 500));
};
