import {
  getIdz,
  getEventManager as getDispatcher,
  getWebsiteId,
  getPlatform,
} from '../../../shared/globals';
import createService from './service';
import registerSetVisitorGdprConsent from './registerSetVisitorGdprConsent';

import * as ConsentEnum from './constants';
import { DispatcherWithRegistry } from '../../../live/src/event-manager/dispatcherWithRegistry';
import {
  BasePayload,
  ButtonClickedPayload,
  CallPayload,
  ChatboxNotificationPayload,
} from '../../../shared/types/events/engagementEvents';
import loggerServiceFactory from '../loggerService/loggerServiceFactory';

export default {
  register: () => {
    const idz = getIdz();
    const websiteId = getWebsiteId();
    const dispatcher = getDispatcher() as DispatcherWithRegistry;
    const platform = getPlatform();

    const gdprConsentFeatureEnabled = idz.engagement.gdprConsent.enabled;
    const recordingConsentEnabled = idz.preferences.recording_enabled;
    const baseUrl = idz.engagement.url;
    const loggerService = loggerServiceFactory(idz.chaturl, websiteId);

    const gdprService = createService(
      baseUrl,
      `${platform}-${websiteId}`,
      platform,
      loggerService,
    );

    function showChatboxButtonClickedHandler(
      event: ChatboxNotificationPayload | ButtonClickedPayload,
    ) {
      dispatcher.emit('engagement.showChatbox', event);
    }

    function showMessageNotificationHandler(event: BasePayload) {
      dispatcher.emit('engagement.showMessage', event);
    }

    function showCallButtonClickedHandler(event: ButtonClickedPayload) {
      const { vuid, ruleId, actionId } = event;
      if (idz.on_call && idz.chat.status === 2) {
        idz.orchestrator.openChatbox({ shouldFocusComposeZone: false });
        return;
      }

      if (!gdprConsentFeatureEnabled) {
        dispatcher.emit(
          'call.callBox.open',
          true,
          !recordingConsentEnabled,
          ruleId,
          actionId,
        );
        return;
      }

      gdprService.getConsentForVuid(vuid).then(
        (result) => {
          dispatcher.emit(
            'call.callBox.open',
            result === ConsentEnum.CONSENTED,
            !recordingConsentEnabled,
            ruleId,
            actionId,
          );
        },
        () => {
          dispatcher.emit('engagement.showCallModalRetrievingFailedWorkflow');
        },
      );
    }

    function callModalSaveConsentHandler({
      selectedCountry,
      formattedNumber,
      callButtonTypeClicked,
    }: CallPayload) {
      if (!gdprConsentFeatureEnabled) {
        dispatcher.emit('engagement.showCallModalWithAcceptedGDPRWorkflow', {
          vuid: idz.vuid,
          id: '',
          selectedCountry,
          formattedNumber,
          callButtonTypeClicked,
        });
        return;
      }

      gdprService.setConsentForVuid(idz.vuid, true).then(
        () => {
          dispatcher.emit('engagement.showCallModalWithAcceptedGDPRWorkflow', {
            vuid: idz.vuid,
            id: '',
            selectedCountry,
            formattedNumber,
            callButtonTypeClicked,
          });
        },
        () => {
          dispatcher.emit('engagement.showCallModalSaveConsentFailedWorkflow');
        },
      );
    }

    dispatcher.on(
      'engagement.showChatboxButtonClicked',
      showChatboxButtonClickedHandler,
    );
    dispatcher.on(
      'engagement.showChatboxNotification',
      showChatboxButtonClickedHandler,
    );
    dispatcher.on(
      'engagement.showMessageNotification',
      showMessageNotificationHandler,
    );

    dispatcher.on(
      'engagement.showCallButtonClicked',
      showCallButtonClickedHandler,
    );
    dispatcher.on(
      'engagement.callModalSaveConsent',
      callModalSaveConsentHandler,
    );

    registerSetVisitorGdprConsent(gdprService, dispatcher);

    //_____________________
    return () => {
      dispatcher.off(
        'engagement.showChatboxButtonClicked',
        showChatboxButtonClickedHandler,
      );
      dispatcher.off(
        'engagement.showChatboxNotification',
        showChatboxButtonClickedHandler,
      );
      dispatcher.off(
        'engagement.showMessageNotification',
        showMessageNotificationHandler,
      );

      dispatcher.off(
        'engagement.showCallButtonClicked',
        showCallButtonClickedHandler,
      );
      dispatcher.off(
        'engagement.callModalSaveConsent',
        callModalSaveConsentHandler,
      );
    };
  },
};
