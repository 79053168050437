import { CONSENTED, DID_NOT_CONSENT } from './constants';

import { getIdz } from '../../../shared/globals';
import { VisitorIdentityService as VisitorIdentityServiceType } from '../../../live/src/visitor-identity/VisitorIdentityService';
import { ExpressedConsent } from '../../../shared/types/consent';
import { getPublicPropertiesStore } from '../../../entry/publicMethods/publicProperties/registerPublicProperties';
import retrier from '../../../shared/utils/retrier';
import { LoggerService } from '../loggerService/types';

const RETRIES = 5;

export default (
  baseUrl: string,
  websiteId: string,
  platform: string,
  logger: LoggerService,
) => ({
  getConsentForVuid: async (vuid: string) => {
    const visitorIdentityService: VisitorIdentityServiceType | {} =
      getIdz().require('visitorIdentityService');
    const jwtIdentity = await (
      visitorIdentityService as VisitorIdentityServiceType
    ).getJwtIdentity();
    const headers = jwtIdentity
      ? { Authorization: `Bearer ${jwtIdentity.jwt}` }
      : undefined;

    try {
      const response = await retrier<Response>(
        () =>
          fetch(
            `${baseUrl}/visitor-consents/ONSITE/${websiteId}/${vuid}?actionId=00000000-0000-0000-0000-000000000000&platform=${platform}`,
            {
              headers,
            },
          ),
        RETRIES,
        (retriesLeft) => 1000 * (RETRIES + 1 - retriesLeft),
      );

      if (!response.ok) {
        throw new Error(
          `Invalid response from consent endpoint: ${response.status}`,
        );
      }
      const payload = await response.json();
      return payload.data === true ? CONSENTED : DID_NOT_CONSENT;
    } catch (error) {
      logger.error(
        'gdpr.service getConsentForVuid error',
        error,
        '[gdpr error]',
        {
          websiteId,
          vuid,
        },
      );
    }
  },
  setConsentForVuid: async (vuid: string, consent: ExpressedConsent) => {
    const visitorIdentityService: VisitorIdentityServiceType | {} =
      getIdz().require('visitorIdentityService');
    const jwtIdentity = await (
      visitorIdentityService as VisitorIdentityServiceType
    ).getJwtIdentity();
    const headers = jwtIdentity
      ? { Authorization: `Bearer ${jwtIdentity.jwt}` }
      : undefined;

    try {
      await retrier<Response>(
        () =>
          fetch(
            `${baseUrl}/visitor-consents/ONSITE/${websiteId}/${vuid}?actionId=00000000-0000-0000-0000-000000000000&platform=${platform}`,
            {
              method: 'PUT',
              headers,
              body: (consent === true).toString(),
            },
          ),
        RETRIES,
        (retriesLeft) => 1000 * (RETRIES + 1 - retriesLeft),
      );
    } catch (error) {
      logger.error(
        'gdpr.service setConsentForVuid error',
        error,
        '[gdpr error]',
        {
          websiteId,
          vuid,
        },
      );
    } finally {
      getPublicPropertiesStore().dispatch('visitor:GDPRConsent', consent);
    }
  },
});
